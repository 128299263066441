 <template>
    <section id="projects-section-id" class="my-p-section">
        <div class="mps-wrapper">
            <h1 class="section-title">my work</h1>
            <div class="projects-container">

                <!-- PROJECT ONE -->
                <div class="project-div spotify-project-one">
                    <div class="project-img">/
                        <img src="../assets/mymuzik.jpeg" alt="project image">
                    </div>
                    <div class="project-name">
                        <h2>Spotify connected app</h2>
                    </div>
                </div>

                <div class="project-info spotify-project" >

                    
                    <div class="pdiv">
                        <div class="close-modal-div">
                            <span class="close-modal">
                                <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" class="close-modal-icon fr-ec-icon fr-ec-icon--default fr-ec-button-icon__icon"><title>Close</title><g fill-rule="evenodd"><polygon points="21 4.6 19.4 3 12 10.4 4.6 3 3 4.6 10.4 12 3 19.4 4.6 21 12 13.6 19.4 21 21 19.4 13.6 12"></polygon></g>
                                </svg>
                            </span>
                        </div>
                        <div class="pdiv-grid">
                            <div class="pdiv-left">
                                <img src="../assets/mymuzik.jpeg" alt="project image">
                            </div>
    
                            <div class="pdiv-right">
                                <div class="project-section-icons-container">
                                    <a  target="_blank" class="code" href="https://github.com/stancillous/">
                                        <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github"><title>GitHub</title><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                                        </svg>
                                    </a>
                                    <div class="github-and-external-link">
                                        <a  target="_blank" href="https://spotifyinsights.me/">
                                            <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><title>External Link</title><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line>
                                            </svg>
                                        </a>
                                    </div>
                                </div>   
        
                                <h1>Spotify Connected App</h1>
                                <p>Integrated with Spotify's API, this web app shows personalized Spotify data. View recently played tracks, top songs, top artists and more. Get recommended artists based on your top artists.</p>
                                <div class="tech-used-container">
                                    <p class="tech-used">Vue.js</p>
                                    <p class="tech-used">Spotify's API</p>
                                 
                                </div>
                            </div>

                        </div>
                    </div>

                </div>  

             <!-- this is the other project that i am adding -->
                <div class="project-div exceed-project-two">
                    <div class="project-img">
                        <img src="../assets/skinsoko.png" alt="project image">
                    </div>
                    <div class="project-name">
                        <h2>Skin Soko</h2>
                    </div>
                </div>

                <div class="project-info exceed-project" >
                    <div class="pdiv">
                        <div class="close-modal-div">
                            <span class="close-modal">
                                <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" class="close-modal-icon fr-ec-icon fr-ec-icon--default fr-ec-button-icon__icon"><title>Close</title><g fill-rule="evenodd"><polygon points="21 4.6 19.4 3 12 10.4 4.6 3 3 4.6 10.4 12 3 19.4 4.6 21 12 13.6 19.4 21 21 19.4 13.6 12"></polygon></g>
                                </svg>
                            </span>
                        </div>
                        <div class="pdiv-grid">
                            <div class="pdiv-left">
                                <img src="../assets/skinsoko.png" alt="project image">
                            </div>

                            <div class="pdiv-right">
                                <div class="project-section-icons-container">
                                    <a  target="_blank" class="code" href="https://github.com/stancillous">
                                    <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github"><title>GitHub</title><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg> 
                                    </a>

                                    <div class="github-and-external-link">
                                        <a  target="_blank" href="https://skinsoko.com/">
                                            <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><title>External Link</title><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line>
                                            </svg>
                                        </a>
                                    </div>
                                </div>   

                                <h1>Skin Soko</h1>
                
                                <p>An e-commerce site for a beauty products shop</p>
                                <div class="tech-used-container">
                                    <p class="tech-used">NuxtJs</p>
                                    <p class="tech-used">Django</p>
                                    <p class="tech-used">PostreSQL</p>
                
                                </div>
                            </div>

                        </div>
                    </div>

                </div>  
             <!-- this is the other project that i am adding -->


                          <!-- this is the other project that i am adding -->
                <div class="project-div country-flag-project">
                    <div class="project-img">
                        <img src="../assets/flagQuiz.png" alt="project image">
                    </div>
                    <div class="project-name">
                        <h2>Country Flag Quiz</h2>
                    </div>
                </div>

                <div class="project-info country-flag-wrp" >
                    <div class="pdiv">
                        <div class="close-modal-div">
                            <span class="close-modal">
                                <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" class="close-modal-icon fr-ec-icon fr-ec-icon--default fr-ec-button-icon__icon"><title>Close</title><g fill-rule="evenodd"><polygon points="21 4.6 19.4 3 12 10.4 4.6 3 3 4.6 10.4 12 3 19.4 4.6 21 12 13.6 19.4 21 21 19.4 13.6 12"></polygon></g>
                                </svg>
                            </span>
                        </div>
                        <div class="pdiv-grid">
                            <div class="pdiv-left">
                                <img src="../assets/flagQuiz.png" alt="project image">
                            </div>

                            <div class="pdiv-right">
                                <div class="project-section-icons-container">
                                    <a  target="_blank" class="code" href="https://github.com/stancillous">
                                    <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github"><title>GitHub</title><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg> 
                                    </a>

                                    <div class="github-and-external-link">
                                        <a  target="_blank" href="https://globalcommunity.app/">
                                            <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><title>External Link</title><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line>
                                            </svg>
                                        </a>
                                    </div>
                                </div>   

                                <h1>Country Flag Quiz</h1>
                
                                <p>A simple guess the country flag quiz.</p>
                                <div class="tech-used-container">
                                    <p class="tech-used">Vue.js</p>
                                    <p class="tech-used">Django</p>
                                    <p class="tech-used">PostreSQL</p>
                
                                </div>
                            </div>

                        </div>
                    </div>

                </div>  

                             <!-- PROJECT TWO -->
                <div class="project-div exceed-shop-project">
                    <div class="project-img">
                        <img src="../assets/exceed-img.png" alt="project image">
                    </div>
                    <div class="project-name">
                        <h2>exceed shop</h2>
                    </div>
                </div>

                <div class="project-info exceed-project-new" >
                    <div class="pdiv">
                        <div class="close-modal-div">
                            <span class="close-modal">
                                <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" class="close-modal-icon fr-ec-icon fr-ec-icon--default fr-ec-button-icon__icon"><title>Close</title><g fill-rule="evenodd"><polygon points="21 4.6 19.4 3 12 10.4 4.6 3 3 4.6 10.4 12 3 19.4 4.6 21 12 13.6 19.4 21 21 19.4 13.6 12"></polygon></g>
                                </svg>
                            </span>
                        </div>
                        <div class="pdiv-grid">
                            <div class="pdiv-left">
                                <img src="../assets/exceed-img.png" alt="project image">
                            </div>

                            <div class="pdiv-right">
                                <div class="project-section-icons-container">
                                    <a  target="_blank" class="code" href="https://github.com/stancillous">
                                    <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github"><title>GitHub</title><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg> 
                                    </a>

                                    <div class="github-and-external-link">
                                        <a  target="_blank" href="https://shop.stanceray.com/">
                                            <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><title>External Link</title><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line>
                                            </svg>
                                        </a>
                                    </div>
                                </div>   

                                <h1>Exceed online shop</h1>
                                <p>An e-commerce website, for a tech shop, that <a style="color: white" href="https://www.brandonmunda.me/">Brandon</a> and I have been working on. Built with Nuxt.js and Django</p>
                                <div class="tech-used-container">
                                    <p class="tech-used">Django</p>
                                    <p class="tech-used">TypeScript</p>
                                    <p class="tech-used">MySQL</p>
                
                                </div>
                            </div>

                        </div>
                    </div>

                </div>  


                             <!-- PROJECT FOUR | Karibu nami web app -->
                <div class="project-div irene-project-five">
                    <div class="project-img">
                        <img src="https://blog.stanceray.com/_nuxt/karibunami-tp.f16a5a0e.png" alt="project image">
                    </div>
                    <div class="project-name">
                        <h2>Karibu Nami web app</h2>
                    </div>
                </div>

                <div class="project-info irene-site-project" >
                    <div class="pdiv">
                        <div class="close-modal-div">
                            <span class="close-modal">
                                <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" class="close-modal-icon fr-ec-icon fr-ec-icon--default fr-ec-button-icon__icon"><title>Close</title><g fill-rule="evenodd"><polygon points="21 4.6 19.4 3 12 10.4 4.6 3 3 4.6 10.4 12 3 19.4 4.6 21 12 13.6 19.4 21 21 19.4 13.6 12"></polygon></g>
                                </svg>
                            </span>
                        </div>
                        <div class="pdiv-grid">
                            <div class="pdiv-left">
                                <img src="https://blog.stanceray.com/_nuxt/karibunami-tp.f16a5a0e.png" alt="project image">
                            </div>

                            <div class="pdiv-right">
                                <div class="project-section-icons-container">
                                    <a  target="_blank" class="code" href="https://github.com/stancillous/KaribuNami">
                                    <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github"><title>GitHub</title><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                                    </svg> 
                                    </a>

                                    <div class="github-and-external-link">
                                        <a  target="_blank" href="https://www.botontapwater.tech/">
                                            <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><title>External Link</title><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line>
                                            </svg>
                                        </a>
                                    </div>
                                </div>

                                <h1>Karibu Nami</h1>
                                <p>Karibu Nami is a web application that I built with <a style="color:white" href="https://github.com/Bot-on-Tapwater">Brandon</a>. The app aims to address the challenge of efficiently accessing and understanding information about local businesses and services when one visits an unfamiliar place</p>
                            
                                <div class="tech-used-container">
                                    <p class="tech-used">Flask</p>
                                    <p class="tech-used">MySQL</p>
                                    <p class="tech-used">Python</p>
                                    <p class="tech-used">SQLAlchemy</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>  

                
               <!-- PROJECT FIVE -->
               <div class="project-div afretis-project-four">
                    <div class="project-img">
                        <img src="../assets/afretis.jpg" alt="project image">
                    </div>
                    <div class="project-name">
                        <h2>Afretis' Website Revamp</h2>
                    </div>
                </div>

                <div class="project-info afretis-project" >
                    <div class="pdiv">
                        <div class="close-modal-div">
                            <span class="close-modal">
                                <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" class="close-modal-icon fr-ec-icon fr-ec-icon--default fr-ec-button-icon__icon"><title>Close</title><g fill-rule="evenodd"><polygon points="21 4.6 19.4 3 12 10.4 4.6 3 3 4.6 10.4 12 3 19.4 4.6 21 12 13.6 19.4 21 21 19.4 13.6 12"></polygon></g>
                                </svg>
                            </span>
                        </div>
                        <div class="pdiv-grid">
                            <div class="pdiv-left">
                                <img src="../assets/afretis.jpg" alt="project image">
                            </div>

                            <div class="pdiv-right">
                                <div class="project-section-icons-container">
                                    <a target="_blank" class="code" href="https://github.com/stancillous/">
                                    <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github"><title>GitHub</title><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                                    </svg> 
                                    </a>

                                    <div class="github-and-external-link">
                                        <a target="_blank"  href="https://afretis.vercel.app/">
                                            <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><title>External Link</title><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line>
                                            </svg>
                                        </a>
                                    </div>
                                </div>   

                                <h1>AFRETIS</h1>
                                <p>Was tasked with revamping the official website for AFRETIS - Africa Energy Transition Investment Summit.</p>
                                <div class="tech-used-container">
                                    <p class="tech-used">Nuxt.js</p>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>  










            </div>
            <div class="ext-cta">
                <a target="_blank" href="https://github.com/stancillous">More on Github</a>
            </div>
        </div>
    </section>

 </template>

<script>
    export default {
        name:'projectsComp'
        
    }
</script>

<style lang="scss" scoped>

</style>
